import React from "react";
import { Link } from "gatsby";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import Products from "~components/products/products";
import Accordion from "~components/accordion/accordion";
import TypeAnimation from "react-type-animation";
import "~styles/pages/services/_design-services.scss";

const steps = [
  "Graphic Design Services",
  500,
  "Illustration Services",
  500,
  "Animation Services",
  500,
  "Annual Report Design",
  500,
  "Sales Presentation Design",
  500,
  "Investor Deck Design",
  500,
  "Anything You Need Designed",
  500,
];

const ContentPage = () => (
  <Layout type="design-services-layout">
    <SEO
      title="Pre-Paid Design Services"
      description="From simple stationery to larger packaging re-design, our pre-paid design services packages are here to help you bring your ideas to life – without any post-project surprise invoices."
    />
    <section className="page-heading">
      <div className="container">
        <TypeAnimation
          cursor={false}
          sequence={steps}
          wrapper="h1"
          className={"title"}
        />
        <div className="design-services-header p-mb">
          <p>
            Imagine that you could get a little bit of project management, a
            little bit of creative direction, a good amount of marketing,
            business and communication understanding and a whole lot of
            technical design skill and knowledge.
          </p>
          <p>
            Well that is what our graphic design and branding support hours are
            made of.
          </p>
          <p>
            Have a design project that you are tired of spinning your wheels on?
          </p>
          <Link to="#design-services" className="CTA_design">
            <span>Order up some hours and let’s get started.</span>
            <i className="blue-arrow-down" />
          </Link>
        </div>
      </div>
    </section>
    <section className="design-services" id="design-services">
      <div className="container">
        <Products>
          <div
            title="Small Fries | 10 Hours"
            subtitle="$960 CAD"
            id="design-small"
          >
            <p>
              This is perfect if you know you are going to need help with a
              bunch of small requests like posters, postcards, business
              cards/stationary, sell sheets, signage, in-store promotional
              material, etc.. Or maybe something a little more intensive like
              creating a brochure or a buyer presentation.
            </p>
          </div>
          <div
            title="Medium Fries | 25 Hours"
            subtitle="$2,280 CAD"
            id="design-medium"
          >
            <p>
              This is what you want when you are planning out a sales deck,
              investor presentation, quarterly/annual report or a longer form
              newsletter. This is also smart when you have regular graphic
              design projects and the discounted hourly rate helps your budget
              go further.
            </p>
          </div>
          <div
            title="Large Fries | 50 Hours"
            subtitle="$4,332 CAD"
            id="design-large"
          >
            <p>
              Maybe you need help developing a packaging concept, or
              fixing/updating some old packaging? What about planning your
              quarterly drop for your retail stores (new promotional material)?
              Maybe it is a larger project that needs some heavy hours, or maybe
              you just need a lot of design support for your social media
              marketing, website updates and digital advertising campaigns. This
              is a great way to reserve the hours and save almost 10% on our
              hourly rates.
            </p>
          </div>
          <div
            title="1 Hour Consultation + Design Brief"
            subtitle="$125 CAD"
            id="design-consultation"
            sectionid="one-hour-design-consultation"
          >
            <p>
              The big reason to do this [almost every time you have a project]
              is to get a design brief that will save you hours/money in the
              long run. That brief is also something you can take to other
              designers for really accurate quotes. More importantly, there is
              no ambiguity, the design team and you are on the same page for
              what the deliverable(s) is/are, how this project will be produced
              and used and what design skills are needed to pull it off.
            </p>
          </div>
        </Products>
      </div>
    </section>
    <section id="faq" className="faq-wrapper">
      <div className="container">
        <h2>FAQ</h2>
        <Accordion>
          <div label="What can be done in an hour?">
            <p>
              Either a whole lot, or absolutely nothing because we didn’t get
              the right notes/brief or explanation. But a lot can be
              accomplished in an hour – especially for our regular clients.
              After about 3-4 projects together, it’s almost like getting a 30%
              speed boost on productivity.
            </p>
          </div>
          <div label="How do I know if I bought enough hours?">
            <p>
              Ask! Use the chatbot below, or if you are a returning client, send
              us a request in ClickUp and we will give you as accurate a time
              budget as we can. Again, the better and more accurate the
              information we get, the more accurate our time budgets will be.
            </p>
          </div>
        </Accordion>
      </div>
    </section>
  </Layout>
);

export default ContentPage;
